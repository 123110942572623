import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import LoadingButton from '@mui/lab/LoadingButton';
import { Grid } from '@mui/material';
import { PaymentMethodsService } from 'api/paymentMethods';
import FormInput from 'components/common/Form/Input';
import FormNumberField from 'components/common/Form/NumberField';
import LocationsIcon from 'components/common/icons/Locations';
import VoucherIcon from 'components/common/icons/Vaucher';
import { useState } from 'react';
import useTranslates from 'utils/translate';
import useMount from 'hooks/useMount';
import useToaster from 'hooks/useToaster';
import PosInfo from './PosInfo';
import useSettings, { IWebsiteConfigs } from 'store/settings';

const locationSlug = 'location';
const voucherSlug = 'voucher';

const ManualDeposit = ({
  control,
  watch,
  onClose,
  setValue,
  getInfoProperties,
  paymentMethod,
  changeAmountFieldVisibility,
  changeActionButtonVisibility,
  changeActionButtonAvailability,
}) => {
  const [activeSlug, setActiveSlug] = useState(locationSlug);
  const [loading, setLoading] = useState(false);
  const { translate } = useTranslates();
  const notify = useToaster();
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();
  useMount(() => {
    changeActionButtonVisibility(false);
    changeAmountFieldVisibility(false);
  });

  const changeActiveSlug = (isLocation: boolean) => {
    setActiveSlug(isLocation ? locationSlug : voucherSlug);
  };

  const sendVoucher = async () => {
    setLoading(true);
    const response = await PaymentMethodsService.getPaymentMethodExtraInfo({
      voucherCode: watch('voucher_code'),
      infoProperties: getInfoProperties(),
      sourceId: paymentMethod.method.id,
      type: 'deposit',
    });
    notify({
      ...(!response && { type: 'error' }),
      message: response ? 'Voucher request sent successfully' : 'Voucher request failed!',
    });
    onClose();
    setLoading(false);
  };

  return (
    <>
      <Tabs value={activeSlug} centered onChange={(_, tab) => changeActiveSlug(tab === locationSlug)}>
        <Tab
          sx={{
            color: activeSlug === locationSlug ? 'primary.main' : 'primary',
          }}
          value={locationSlug}
          label={translate('location')}
          color="primary"
          icon={<LocationsIcon />}
          iconPosition="end"
        />
        <Tab
          sx={{
            color: activeSlug === voucherSlug ? 'primary.main' : 'primary',
          }}
          value={voucherSlug}
          icon={<VoucherIcon />}
          iconPosition="end"
          label={translate('voucher')}
        />
      </Tabs>
      {activeSlug === locationSlug && (
        <>
          <Grid item xs={12}>
            <FormNumberField
              maskFormat
              label={translate('payment_amount')}
              name="amount"
              placeholder={translate('payment_amount')}
              control={control}
            />
          </Grid>
          {watch('amount') && (
            <PosInfo
              watch={watch}
              isDeposit
              control={control}
              setValue={setValue}
              paymentMethod={paymentMethod}
              getInfoProperties={getInfoProperties}
              changeActionButtonAvailability={changeActionButtonAvailability}
            />
          )}
        </>
      )}
      {activeSlug === voucherSlug && (
        <>
          <Grid item xs={12}>
            <FormInput label={translate('voucher_code')} name="voucher_code" required control={control} />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <LoadingButton
              loading={loading}
              disabled={!watch('voucher_code')}
              variant="contained"
              style={{
                color: meta.customColors?.paymentModalAction || 'success',
                backgroundColor: meta.customColors?.paymentModalActionBg || 'primary.main',
              }}
              sx={{ mt: 3 }}
              type="submit"
              onClick={sendVoucher}
            >
              {translate('submit_voucher')}
            </LoadingButton>
          </Grid>
        </>
      )}
    </>
  );
};

export default ManualDeposit;
