import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';

import Form from 'components/common/Form/Form';
import FormInput from 'components/common/Form/Input';
import FormPasswordField from 'components/common/Form/Password';
import CloseIcon from 'components/common/svg/close';

import { signInSchema } from 'config/resolvers';

import AuthService from 'api/auth/AuthService';
import useDialog from 'store/dialog';

import TelegramLogin from 'components/common/TelegramLogin';
import useCurrentUser from 'store/currentUser';
import useDomainSettings from 'store/domains';
import useSettings, { IWebsiteConfigs } from 'store/settings';
import useTranslates from 'utils/translate';
import useSockets from 'hooks/useSockets';
import useGlobalSettings from 'store/globalSettings';
import { TwoFactorAuthenticationState } from 'api/settings';

interface SignInPopupProps {
  open: boolean;
  onClose: () => void;
}

const SignInPopup: FC<SignInPopupProps> = ({ open, onClose }) => {
  const { translate } = useTranslates();

  const socket = useSockets();
  const [, dialogActions] = useDialog();
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();
  const [, currentUserActions] = useCurrentUser();
  const [
    {
      data: { telegramBotName },
    },
  ] = useDomainSettings();
  const [globalSettings] = useGlobalSettings();

  const [isLoading, setIsLoading] = useState(false);
  const { control, reset, handleSubmit } = useForm({
    resolver: signInSchema,
  });

  useEffect(() => {
    reset({});
  }, [open]);

  const onSignIn = async (data) => {
    setIsLoading(true);
    AuthService.signIn({
      ...data,
      email: data.email?.toLowerCase(),
    })
      .then((res) => {
        onClose();

        if (
          globalSettings.data.twoFactorState === TwoFactorAuthenticationState.mandatory ||
          res.isTwoFactorAuthenticationEnabled
        ) {
          dialogActions.set('2fa');
          return;
        }
        currentUserActions.set(res);
        socket.connect();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSuccess = (user) => {
    AuthService.telegramAuth(user).then((res) => {
      onClose();
      currentUserActions.set(res);
      socket.connect();
    });
  };

  return (
    <Dialog keepMounted open={open} onClose={() => !isLoading && onClose()}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('sign_in')}
        </Typography>
        <IconButton onClick={() => !isLoading && onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit(onSignIn)}>
          <Stack gap={2} key={open ? 1 : 2}>
            <FormInput
              name="email"
              control={control}
              disabled={isLoading}
              placeholder={translate('email_address_or_username')}
            />
            <FormPasswordField control={control} disabled={isLoading} />
            <Button type="submit" variant="contained" disabled={isLoading} data-testid="login-button">
              {translate('sign_in')}
            </Button>
            {!!telegramBotName && <TelegramLogin botName={telegramBotName} onSuccess={onSuccess} />}
            <Stack gap={1}>
              <Button size="small" fullWidth onClick={() => dialogActions.set('forgotPassword')}>
                {translate('forgot_password')}
              </Button>
              {meta.showSignup && (
                <Button size="small" fullWidth onClick={() => dialogActions.set('signUp')} data-testid="sign-up-button">
                  {translate('sign_up')}
                </Button>
              )}
            </Stack>
          </Stack>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default SignInPopup;
