import { useSearchParams } from 'react-router-dom';

import useDialog from 'store/dialog';
import { useEffect } from 'react';

const supportedQueries = {
  token: 'token',
  dialog: 'dialog',
  resetPassword: 'resetPassword',
  emailConfirmation: 'emailConfirmation',
};

const useSearchParamsTriggers = () => {
  const [params, setParams] = useSearchParams();
  const [, dialogActions] = useDialog();

  useEffect(() => {
    if (params.get(supportedQueries.resetPassword)) {
      if (params.get(supportedQueries.token)) {
        dialogActions.set('resetPassword');
      }
      return;
    }
    if (params.get(supportedQueries.emailConfirmation)) {
      if (params.get(supportedQueries.token)) {
        dialogActions.set('emailConfirmation');
      }
      return;
    }
    if (params.get(supportedQueries.dialog) === 'signUp') {
      dialogActions.set('signUp');
      setParams((prevSearchParams) => {
        prevSearchParams.delete(supportedQueries.dialog);
        return prevSearchParams;
      });
    }
  }, [params]);
};

export default useSearchParamsTriggers;
