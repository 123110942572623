import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import useSettings from 'store/settings';
import useWebsiteMetas from 'store/metas';
import useSelectedLanguage from 'store/language';

import useTranslates from 'utils/translate';

import { PAGE_META_KEYS, DEFAULT_META_KEY, APPLICATION_NAME } from 'config';

const Metatags = () => {
  const location = useLocation();
  const { translate } = useTranslates();
  const [selectedLanguage] = useSelectedLanguage();

  const [settings] = useSettings();
  const [state, actions] = useWebsiteMetas();

  useEffect(() => {
    if (selectedLanguage?.id) {
      actions.read();
    }
  }, [selectedLanguage?.id]);

  const selected = state?.data?.find(
    (item) => item.page === location?.pathname || PAGE_META_KEYS[location?.pathname] === item.page
  );

  const defaultSelected = state?.data?.find((item) => item.page === DEFAULT_META_KEY);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="apple-touch-icon" href={settings.data?.favicon} />
        <link rel="icon" href={settings.data?.favicon} type="image/x-icon" />
        <link rel="shortcut icon" href={settings.data?.favicon} type="image/x-icon" />
        <title>
          {state?.status === 'loading'
            ? translate('meta_loading')
            : selected?.metaTitle || defaultSelected?.metaTitle || APPLICATION_NAME}
        </title>
        <meta
          name="application-name"
          content={selected?.metaTitle || defaultSelected?.metaTitle || APPLICATION_NAME}
        />
        <meta name="keywords" content={selected?.metaKeywords || defaultSelected?.metaKeywords} />
        <meta name="description" content={selected?.metaDescription || defaultSelected?.metaDescription} />
      </Helmet>
    </>
  );
};

export default Metatags;
