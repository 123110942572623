import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import BoltIcon from '@mui/icons-material/Bolt';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';

import FormDatePicker from 'components/common/Form/DatePicker';
import Form from 'components/common/Form/Form';
import FormInput from 'components/common/Form/Input';
import FormPasswordField from 'components/common/Form/Password';
import FormPhoneInput from 'components/common/Form/PhoneInput';
import FormSelect from 'components/common/Form/Select';
import CloseIcon from 'components/common/svg/close';

import useCurrentUser from 'store/currentUser';
import useDialog from 'store/dialog';
import useGlobalSettings from 'store/globalSettings';
import useSelectedLanguage from 'store/language';
import useSettings, { IWebsiteConfigs } from 'store/settings';

import AuthService from 'api/auth/AuthService';

import { oneClickSignUpSchema, signUpSchema, telegramSignupSchema } from 'config/resolvers';
import useTranslates from 'utils/translate';
import useSockets from 'hooks/useSockets';
import useAnalytics from 'hooks/useAnalytics';
import useMount from 'hooks/useMount';
import { AnalyticsKey, AnalyticsPageViewEvents } from 'hooks/useAnalytics/enum';
import { TwoFactorAuthenticationState } from 'api/settings';
import SignupActions from './Footer';

interface SignUpPopupProps {
  open: boolean;
  onClose: () => void;
}

const oneTimeRegistrationSlug = 'one-time-registration';
const regularRegistrationSlug = 'regular-registration';

const SignUpPopup: FC<SignUpPopupProps> = ({ open, onClose }) => {
  const socket = useSockets();
  const [telegramUser, setTelegramUser] = useState(null);
  const { translate } = useTranslates();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const [language] = useSelectedLanguage();
  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();
  const [globalSettings] = useGlobalSettings();
  const [, dialogActions] = useDialog();
  const [activeSlug, setActiveSlug] = useState(regularRegistrationSlug);

  const [, currentUserActions] = useCurrentUser();
  const { sendEvent } = useAnalytics();
  const { control, setValue, reset, handleSubmit } = useForm({
    resolver: signUpSchema({
      playerMinAge: globalSettings?.data?.playerMinAge,
      isPhoneNumberRequired: meta.isPhoneNumberRequired,
      isPhoneNumberDisabled: meta.isPhoneNumberDisabled,
    }),
  });

  const {
    control: telegramControl,
    setValue: setTelegramValue,
    handleSubmit: handleTelegramSubmit,
  } = useForm({
    resolver: telegramSignupSchema,
  });

  const {
    control: oneClickControl,
    setValue: setOneClickValue,
    handleSubmit: handleOneClickSubmit,
  } = useForm({
    resolver: oneClickSignUpSchema,
  });

  useEffect(() => {
    reset({});
  }, [open]);

  useMount(() => {
    sendEvent({ [AnalyticsKey.pageView]: AnalyticsPageViewEvents.registrationStart });
  });

  const onSignUp = async (data) => {
    setIsLoading(true);
    try {
      await AuthService.signUp({
        ...data,
        email: data.email?.toLowerCase(),
        username: (data.username as string)?.toLowerCase().trimEnd(),
        birthDate: new Date(data.birthDate).toISOString().split('T')[0],
        languageId: language.id,
        query: searchParams.toString(),
      });

      sendEvent({ [AnalyticsKey.pageView]: AnalyticsPageViewEvents.registrationSuccess });

      if (meta.autoVerifyEmail) {
        const response = await AuthService.signIn({ email: data.email?.toLowerCase(), password: data.password });
        onClose();
        if (globalSettings.data.twoFactorState === TwoFactorAuthenticationState.mandatory) {
          return dialogActions.set('2fa');
        }
        if (response.id) {
          currentUserActions.set(response);
          socket.connect();
        }
      } else {
        dialogActions.set('verifyEmail');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onOneClickSignup = async (data) => {
    setIsLoading(true);
    try {
      const { username, password } = await AuthService.oneClickSignUp({
        ...data,
        languageId: language.id,
        query: searchParams.toString(),
      });

      sendEvent({ [AnalyticsKey.pageView]: AnalyticsPageViewEvents.registrationSuccess });

      dialogActions.set('credentials', { username, password });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onSuccess = (user) => {
    setTelegramUser(user);
  };

  useEffect(() => {
    if (globalSettings.data.currencies.length) {
      const currencyId = globalSettings.data.currencies[0].id;
      if (telegramUser) {
        setTelegramValue('currencyId', currencyId);
      }
      if (activeSlug === oneTimeRegistrationSlug) {
        setOneClickValue('currencyId', currencyId);
      }

      if (activeSlug === regularRegistrationSlug) {
        setValue('currencyId', currencyId);
      }
    }
  }, [activeSlug, globalSettings.data.currencies, telegramUser]);

  const onTelegramSignUp = (data) => {
    setIsLoading(true);
    AuthService.telegramAuth({
      ...telegramUser,
      languageId: language.id,
      currencyId: data.currencyId,
    }).then((res) => {
      setIsLoading(false);
      onClose();
      currentUserActions.set(res);
      socket.connect();
    });
  };

  if (telegramUser) {
    return (
      <Dialog keepMounted open={open} onClose={onClose}>
        <DialogTitle>
          <Typography fontSize={18} fontWeight={600}>
            {translate('last_step')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Form onSubmit={handleTelegramSubmit(onTelegramSignUp)}>
            <Stack gap={2}>
              <FormSelect
                disabled={isLoading}
                control={telegramControl}
                name="currencyId"
                placeholder={translate('currency')}
                options={globalSettings?.data?.currencies?.map((item) => ({
                  id: item.id,
                  name: item.code,
                }))}
              />
              <Button disabled={isLoading} variant="contained" type="submit" data-testid="sign-up-button">
                {translate('sign_up')}
              </Button>
            </Stack>
          </Form>
        </DialogContent>
      </Dialog>
    );
  }

  const isOneClickRegistration = meta.onClickRegistration && activeSlug === oneTimeRegistrationSlug;
  const isRegularRegistration = activeSlug === regularRegistrationSlug;

  return (
    <Dialog keepMounted open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('sign_up')}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {open && (
          <>
            {meta.onClickRegistration && (
              <Tabs
                style={{ marginTop: '-32px', marginBottom: '16px' }}
                value={activeSlug}
                centered
                onChange={(_, nextSlug) => setActiveSlug(nextSlug)}
              >
                <Tab
                  sx={{
                    color: isRegularRegistration ? 'primary.main' : 'primary',
                  }}
                  value={regularRegistrationSlug}
                  label={translate('regular_reg')}
                  color="primary"
                  icon={<HowToRegIcon />}
                  iconPosition="start"
                />
                <Tab
                  sx={{
                    color: isOneClickRegistration ? 'primary.main' : 'primary',
                  }}
                  value={oneTimeRegistrationSlug}
                  label={translate('one_time_reg')}
                  icon={<BoltIcon />}
                  iconPosition="start"
                />
              </Tabs>
            )}
            <Form
              onSubmit={isOneClickRegistration ? handleOneClickSubmit(onOneClickSignup) : handleSubmit(onSignUp)}
              key={activeSlug}
            >
              <Stack gap={2}>
                {isOneClickRegistration ? (
                  <>
                    <FormSelect
                      disabled={isLoading}
                      control={oneClickControl}
                      name="currencyId"
                      placeholder={translate('currency')}
                      options={globalSettings?.data?.currencies?.map((item) => ({
                        id: item.id,
                        name: item.code,
                      }))}
                    />
                    {meta.showSignupPromoCode && (
                      <FormInput
                        onInterceptValue={(event): ChangeEvent<HTMLInputElement | HTMLTextAreaElement> => {
                          event.target.value = (event.target.value || '')?.toLowerCase();
                          return event;
                        }}
                        disabled={isLoading}
                        control={oneClickControl}
                        name="promoCode"
                        placeholder={translate('promo_code')}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <FormInput
                      disabled={isLoading}
                      control={control}
                      name="username"
                      placeholder={translate('username')}
                    />
                    <FormInput
                      disabled={isLoading}
                      control={control}
                      name="firstName"
                      placeholder={translate('name')}
                    />
                    <FormInput
                      disabled={isLoading}
                      control={control}
                      name="lastName"
                      placeholder={translate('surname')}
                    />
                    <FormInput
                      disabled={isLoading}
                      control={control}
                      name="email"
                      placeholder={translate('email_address')}
                    />
                    <FormDatePicker disabled={isLoading} control={control} name="birthDate" />
                    <FormSelect
                      disabled={isLoading}
                      control={control}
                      name="currencyId"
                      placeholder={translate('currency')}
                      options={globalSettings?.data?.currencies?.map((item) => ({
                        id: item.id,
                        name: item.code,
                      }))}
                    />
                    {!meta.isPhoneNumberDisabled && (
                      <FormPhoneInput
                        defaultCountry={globalSettings.data.locale}
                        name="phoneNumber"
                        disabled={isLoading}
                        control={control}
                      />
                    )}
                    {meta.showSignupPromoCode && (
                      <FormInput
                        onInterceptValue={(event): ChangeEvent<HTMLInputElement | HTMLTextAreaElement> => {
                          event.target.value = (event.target.value || '')?.toLowerCase();
                          return event;
                        }}
                        disabled={isLoading}
                        control={control}
                        name="promoCode"
                        placeholder={translate('promo_code')}
                      />
                    )}
                    <Typography fontSize={12}>{translate('password_must_contain')}</Typography>
                    <FormPasswordField disabled={isLoading} control={control} name="password" />
                    <FormPasswordField
                      disabled={isLoading}
                      control={control}
                      name="confirm_password"
                      label={translate('repeat_password')}
                    />
                  </>
                )}
                <SignupActions isLoading={isLoading} onTelegramSuccess={onSuccess} />
              </Stack>
            </Form>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SignUpPopup;
